import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  LocalStorageKey,
  TypeShowValue
} from '../../const.js'

import {
  fetchFirmsByIdUser,
  fetchProductWithDetailStats
} from '../../store/api-actions.js';
import {
  getCurrentUser,
  getAllFirms,
  getStatusLoadProduct,
  getProductWithDetailStats,
  getTypeShowValue
} from '../../store/app-data/selectors.js';

import FirmsSummaryStats from '../../components/firms-summary-stats/firms-summary-stats.jsx';
import Preloader from '../../components/preloader/preloader.jsx';

import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Brush
} from 'recharts';
import {setTypeShowValue} from "../../store/app-data/app-data";

const ProductMonitorPage = () => {
  const dispatch = useDispatch();

  const { idProduct } = useParams();

  const isLoadProduct = useSelector(getStatusLoadProduct);
  const currentUser = useSelector(getCurrentUser);
  const typeShowValue = useSelector(getTypeShowValue);
  const { id: idUser } = currentUser;

  const dateStartLoad = dayjs().add(-7, 'day');
  const dateEndLoad = dayjs().endOf('day');
  const [dateStart, setDateStart] = useState(dateStartLoad);
  const [dateEnd, setDateEnd] = useState(dateEndLoad);

  useEffect(() => {
    dispatch(fetchFirmsByIdUser(idUser));
    dispatch(fetchProductWithDetailStats({
      idUser: idUser,
      idProduct: idProduct,
      dateStart: dateStart,
      dateEnd: dateEnd
    }));
  }, []);

  const product = useSelector(getProductWithDetailStats);
  console.log(product);
  const firms = useSelector(getAllFirms);

  const statsFirms = getStatsAboutFirms(product.stats, firms);
  const datesStatsFirmsSet = statsFirms ? getDateStatsFirms(statsFirms) : [];
  const dataGraph = datesStatsFirmsSet ? getStatsForGraph(datesStatsFirmsSet, statsFirms, firms, typeShowValue) : [];

  const minValuesStats = getMinValuesStatsFirms(statsFirms, firms, typeShowValue);
  const mainFirmMinValue = minValuesStats ? minValuesStats[0] : {};
  const averageValuesStats = getAverageValuesStatsFirms(statsFirms, firms, typeShowValue);
  const mainFirmAverageValue = averageValuesStats ? averageValuesStats[0] : {};
  const maxValuesStats = getMaxValuesStatsFirms(statsFirms, firms, typeShowValue);
  const mainFirmMaxValue = maxValuesStats ? maxValuesStats[0]: {};

  if (product.stats?.length === 0) {
    return (
      <>
        <section className="page-content page__content">
          <header className="page-content__header standart-block">
            <div className="">
              <h1 className="header header--1">
                {

                }
              </h1>
            </div>
          </header>

          <div className="product-monitor page-content__inner">
            <div className="standart-block">
              <h2 className="header header--2">Недостаточно данных</h2>

              <p>Пожалуйста, привяжите хотя бы одну ссылку к товару.</p>
            </div>
          </div>
        </section>
      </>
    )
  }

  return (
    <>
      <section className="page-content page__content">
        <header className="page-content__header standart-block">
          <div className="">
            <h1 className="header header--1">{truncate(product.name)}</h1>
          </div>

          <div className="date-select">
            <input
              className="date-select__input date-select__from"
              name="date-from"
              type="date"
              defaultValue={dayjs(dateStart).format('YYYY-MM-DD')}
              alt="Дата от"
              onChange={(evt) => {
                setDateStart(dayjs(evt.target.value).startOf('day'));

                dispatch(fetchProductWithDetailStats({
                  idUser: idUser,
                  idProduct: idProduct,
                  dateStart: dayjs(evt.target.value).startOf('day'),
                  dateEnd: dateEnd
                }));
              }}
            />
            <span> - </span>
            <input
              className="date-select__input date-select__to"
              name="date-select"
              type="date"
              defaultValue={dateEnd.format('YYYY-MM-DD')}
              alt="Дата до"
              onChange={(evt) => {
                setDateEnd(dayjs(evt.target.value).endOf('day'));

                dispatch(fetchProductWithDetailStats({
                  idUser: idUser,
                  idProduct: idProduct,
                  dateStart: dateStart,
                  dateEnd: dayjs(evt.target.value).endOf('day')
                }));
              }}
            />
          </div>
        </header>

        <div className="product-monitor page-content__inner">
          <div className="product-monitor__dynamic standart-block">
            <header className="product-monitor__header">
              <h2 className="header header--2">
                Динамика изменения {typeShowValue === TypeShowValue.PRICE ? ' цен ' : ' наличия '} с {dayjs(dateStart).format('DD.MM.YYYY')} по {dayjs(dateEnd).format('DD.MM.YYYY')}
              </h2>

              <div className="product-monitor__settings">
                <ul className="toggle-data-list product-monitor__toggle-data-list">
                  <li
                    className={`toggle-data-list__item ${
                      typeShowValue === TypeShowValue.PRICE && 'toggle-data-list__item--active'
                    }`
                    }
                    onClick={() => {
                      dispatch(setTypeShowValue(TypeShowValue.PRICE))
                    }}
                  >
                    Цены
                  </li>
                  <li
                    className={`toggle-data-list__item ${
                      typeShowValue === TypeShowValue.COUNT && 'toggle-data-list__item--active'
                    }`
                    }
                    onClick={() => {
                      dispatch(setTypeShowValue(TypeShowValue.COUNT));
                    }}
                  >
                    Остатки
                  </li>
                </ul>

                {/*<button className="button product-monitor__triggers-button">Триггеры</button>*/}

                {/*<button className="button button--no-background">*/}
                {/*  <svg className="buttons-list__icon" width="26" height="26" viewBox="0 0 26 26" fill="none">*/}
                {/*    <path d="M24.7133 16.1242L22.4674 14.8347C22.6941 13.6185 22.6941 12.371 22.4674 11.1548L24.7133 9.86533C24.9717 9.71856 25.0877 9.41453 25.0033 9.13147C24.4181 7.26534 23.4217 5.57745 22.1195 4.17261C21.9191 3.9577 21.5922 3.90528 21.3392 4.05205L19.0933 5.34156C18.1496 4.53431 17.0635 3.91052 15.8878 3.50165V0.927868C15.8878 0.634321 15.6822 0.377467 15.3922 0.314564C13.4574 -0.115273 11.4751 -0.0943054 9.6351 0.314564C9.34514 0.377467 9.13952 0.634321 9.13952 0.927868V3.50689C7.96912 3.921 6.88306 4.54479 5.93408 5.3468L3.69343 4.05729C3.4351 3.91052 3.1135 3.9577 2.91316 4.17786C1.61095 5.57745 0.614523 7.26534 0.0293192 9.13671C-0.0603066 9.41977 0.0609519 9.7238 0.319285 9.87058L2.5652 11.1601C2.3385 12.3762 2.3385 13.6238 2.5652 14.8399L0.319285 16.1294C0.0609519 16.2762 -0.0550345 16.5802 0.0293192 16.8633C0.614523 18.7294 1.61095 20.4173 2.91316 21.8221C3.1135 22.0371 3.44037 22.0895 3.69343 21.9427L5.93935 20.6532C6.88306 21.4605 7.96912 22.0842 9.1448 22.4931V25.0721C9.1448 25.3657 9.35041 25.6225 9.64037 25.6854C11.5752 26.1153 13.5576 26.0943 15.3975 25.6854C15.6875 25.6225 15.8931 25.3657 15.8931 25.0721V22.4931C17.0635 22.079 18.1496 21.4552 19.0985 20.6532L21.3445 21.9427C21.6028 22.0895 21.9244 22.0423 22.1247 21.8221C23.4269 20.4226 24.4234 18.7347 25.0086 16.8633C25.0877 16.575 24.9717 16.271 24.7133 16.1242ZM12.5137 17.1883C10.1887 17.1883 8.29599 15.3064 8.29599 12.9948C8.29599 10.6831 10.1887 8.80123 12.5137 8.80123C14.8387 8.80123 16.7314 10.6831 16.7314 12.9948C16.7314 15.3064 14.8387 17.1883 12.5137 17.1883Z" fill="#BE1622"/>*/}
                {/*  </svg>*/}
                {/*</button>*/}
              </div>
            </header>

            {
              isLoadProduct ? <Preloader /> :
                <div className="product-monitor__graph-block">
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                  >
                    <LineChart
                      data={dataGraph}
                      margin={{
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 30
                      }}
                    >
                      <CartesianGrid
                        strokeDasharry="3 3"
                      />
                      <XAxis
                        dataKey="name"
                        padding={{left: 50, right: 50}}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {
                        firms.map((firm) => {
                          if (firm.isSelect) {
                            return (
                              <Line
                                connectNulls
                                unit={
                                  typeShowValue === TypeShowValue.PRICE ? ' руб.' : ' шт.'
                                }
                                type="monotone"
                                dataKey={getFirmName(firm.id, firms)}
                                stroke={getFirmColor(firm.id, firms)}
                                strokeWidth={2}
                                activeDot={{r: 8}}
                              />
                            );
                          }
                        })
                      }
                    </LineChart>
                  </ResponsiveContainer>
                </div>
            }
          </div>

          <FirmsSummaryStats
            minPrice={product.minAllPrice}
            averagePrice={product.averageAllPrice}
            maxPrice={product.maxAllPrice}
            minCount={product.minAllCount}
            averageCount={product.averageAllCount}
            maxCount={product.maxAllCount}
            typeShowValue={typeShowValue}
          />

          <div className="firms-summary-stat">
            <ul className="firms-summary-stats__list">
              <li className="standart-block firms-summary-stats__item">
                <h3 className="header header--3 header--center firms-summary-stats__header">
                  {
                    typeShowValue === TypeShowValue.PRICE ? 'минимальные цены' : 'минимальное количество'
                  }
                </h3>
                <div className="firms-summary-stats__stat">
                  <div className="firms-summary-stats__main-stat">
                    <div style={{backgroundColor: mainFirmMinValue?.color}} className="circle"/>
                    <div className="firms-summary-stats__main-numbers">
                      <div className="firms-summary-stats__main-number">
                        {
                          typeShowValue === TypeShowValue.PRICE && mainFirmMinValue && `${mainFirmMinValue?.minPrice?.toLocaleString()} руб.`
                        }

                        {
                          typeShowValue === TypeShowValue.COUNT && mainFirmMinValue && `${mainFirmMinValue?.minCount?.toLocaleString()} шт.`
                        }
                      </div>
                      <div className="firms-summary-stats__main-firm">
                        {
                          mainFirmMinValue?.name
                        }
                      </div>
                    </div>
                  </div>

                  <ul className="firms-summary-stats__list-other-stat">
                    {
                      minValuesStats?.map((stat) => {
                        if (stat.idFirm !== mainFirmMinValue.idFirm) {
                          const style= {
                            backgroundColor: stat.color
                          }

                          return (
                            <li key={stat.idFirm} className="firms-summary-stats__item-other-stat">
                              <span style={style} className="circle" />
                              <div  className="">
                                {
                                  typeShowValue === TypeShowValue.PRICE && `${stat?.minPrice?.toLocaleString()} руб.`
                                }

                                {
                                  typeShowValue === TypeShowValue.COUNT && `${stat?.minCount?.toLocaleString()} шт.`
                                }
                              </div>
                            </li>
                          )
                        }
                      })
                    }
                  </ul>
                </div>
              </li>

              <li className="standart-block firms-summary-stats__item">
                <h3 className="header header--3 header--center firms-summary-stats__header">
                  {
                    typeShowValue === TypeShowValue.PRICE ? 'средние цены' : 'среднее количество'
                  }
                </h3>
                <div className="firms-summary-stats__stat">
                  <div className="firms-summary-stats__main-stat">
                    <div style={{backgroundColor: mainFirmAverageValue?.color}} className="circle"/>
                    <div className="firms-summary-stats__main-numbers">
                      <div className="firms-summary-stats__main-number">
                        {
                          typeShowValue === TypeShowValue.PRICE && `${mainFirmAverageValue?.averagePrice?.toLocaleString()} руб.`
                        }
                        {
                          typeShowValue === TypeShowValue.COUNT && `${mainFirmAverageValue?.averageCount?.toLocaleString()} шт.`
                        }
                      </div>
                      <div className="firms-summary-stats__main-firm">
                        { mainFirmAverageValue?.name }
                      </div>
                    </div>
                  </div>

                  <ul className="firms-summary-stats__list-other-stat">
                    {
                      averageValuesStats?.map((stat) => {
                        if (stat.idFirm !== mainFirmAverageValue.idFirm) {
                          const style= {
                            backgroundColor: stat.color
                          }

                          return (
                            <li key={stat.idFirm} className="firms-summary-stats__item-other-stat">
                              <span style={style} className="circle" />
                              <div  className="">
                                {
                                  typeShowValue === TypeShowValue.PRICE && `${stat?.averagePrice?.toLocaleString()} руб.`
                                }

                                {
                                  typeShowValue === TypeShowValue.COUNT && `${stat?.averageCount?.toLocaleString()} шт.`
                                }
                              </div>
                            </li>
                          )
                        }
                      })
                    }
                  </ul>
                </div>
              </li>

              <li className="standart-block firms-summary-stats__item">
                <h3 className="header header--3 header--center firms-summary-stats__header">
                  {
                    typeShowValue === TypeShowValue.PRICE ? 'максимальные цены' : 'максимальное количество'
                  }
                </h3>
                <div className="firms-summary-stats__stat">
                  <div className="firms-summary-stats__main-stat">
                    <div style={{backgroundColor: mainFirmMaxValue?.color}} className="circle circle--blue"/>
                    <div className="firms-summary-stats__main-numbers">
                      <div className="firms-summary-stats__main-number">
                        {
                          typeShowValue === TypeShowValue.PRICE && `${mainFirmMaxValue?.maxPrice?.toLocaleString()} руб.`
                        }

                        {
                          typeShowValue === TypeShowValue.COUNT && `${mainFirmMaxValue?.maxCount?.toLocaleString()} шт.`
                        }
                      </div>
                      <div className="firms-summary-stats__main-firm">
                        {mainFirmMaxValue?.name}
                      </div>
                    </div>
                  </div>
                  <ul className="firms-summary-stats__list-other-stat">
                    {
                      maxValuesStats?.map((stat) => {
                        if (stat.idFirm !== mainFirmMaxValue.idFirm) {
                          const style= {
                            backgroundColor: stat.color
                          }

                          return (
                            <li key={stat.idFirm} className="firms-summary-stats__item-other-stat">
                              <span style={style} className="circle" />
                              <div  className="">
                                {
                                  typeShowValue === TypeShowValue.PRICE && `${stat?.maxPrice?.toLocaleString()} руб.`
                                }
                                {
                                  typeShowValue === TypeShowValue.COUNT && `${stat?.maxCount?.toLocaleString()} шт.`
                                }
                              </div>
                            </li>
                          )
                        }
                      })
                    }
                  </ul>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </section>
    </>
  )
}

const getStatsForGraph = (datesSet, stats, firms, typeShowValue = TypeShowValue.PRICE) => {
  const statsResult = [];

  for (let date of datesSet) {
    const objectPoint = {};

    for (let stat of stats) {
      const statsFirm = stat.stats;

      let valueByFirm = null;

      if (typeShowValue === TypeShowValue.PRICE) {
        valueByFirm = statsFirm?.find((statFirm) => {
          return date === statFirm.createdAt;
        })?.price;
      } else {
        valueByFirm = statsFirm?.find((statFirm) => {
          return date === statFirm.createdAt;
        })?.count;
      }

      Object.assign(
        objectPoint,
        {name: dayjs(date).format('DD.MM.YYYY HH:mm')},
        {[`${getFirmName(stat.idFirm, firms)}`]: valueByFirm})
    }

    statsResult.push(objectPoint);
  }

  return statsResult;
}

const getStatsAboutFirms = (stats, firms) => {
  if (typeof stats !== 'undefined') {
    return stats.filter((stat) => {
      if (stat.linkProduct !== null) {
        return {
          idFirm: stat.idFirm,
          minPrice: stat.minPrice,
          averagePrice: stat.averagePrice,
          maxPrice: stat.maxPrice,
          minCount: stat.minCount,
          averageCount: stat.averageCount,
          maxCount: stat.maxCount
        }
      }
    });
  }
}

const getDateStatsFirms = (stats) => {
  const resultStats = stats.map((stat) => {
    return stat.stats?.map((statDetail) => statDetail.createdAt);
  });

  return new Set(resultStats
    .flat(Infinity)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()));
}


const getMinValuesStatsFirms = (stats, firms, typeShowValue = TypeShowValue.PRICE) => {
  if (typeof stats !== 'undefined') {
    const statsResult = stats.map((stat) => {
      return {
        idFirm: stat.idFirm,
        name: getFirmName(stat.idFirm, firms),
        minPrice: stat.minPrice,
        minCount: stat.minCount,
        color: getFirmColor(stat.idFirm, firms)
      }
    });

    return typeShowValue === TypeShowValue.PRICE ?
      statsResult.sort((a, b) => a.minPrice - b.minPrice)
      : statsResult.sort((a, b) => a.minCount - b.minCount)
  }

  return null;
}

const getAverageValuesStatsFirms = (stats, firms, typeShowValue = TypeShowValue.PRICE) => {
  if (typeof stats !== 'undefined') {
    const statsResult = stats.map((stat) => {
      return {
        idFirm: stat.idFirm,
        name: getFirmName(stat.idFirm, firms),
        averagePrice: parseInt(stat.averagePrice, 10),
        averageCount: parseInt(stat.averageCount, 10),
        color: getFirmColor(stat.idFirm, firms)
      }
    });

    return typeShowValue === TypeShowValue.PRICE ?
      statsResult.sort((a, b) => a.averagePrice - b.averagePrice)
      : statsResult.sort((a, b) => a.averageCount - b.averageCount)
  }

  return null;
}

const getMaxValuesStatsFirms = (stats, firms, typeShowValue = TypeShowValue.PRICE) => {
  if (typeof stats !== 'undefined') {
    const statsResult = stats.map((stat) => {
      return {
        idFirm: stat.idFirm,
        name: getFirmName(stat.idFirm, firms),
        maxPrice: stat.maxPrice,
        maxCount: stat.maxCount,
        color: getFirmColor(stat.idFirm, firms)
      }
    });

    return typeShowValue === TypeShowValue.PRICE ?
      statsResult.sort((a, b) => b.maxPrice - a.maxPrice)
      : statsResult.sort((a, b) => b.maxCount - a.maxCount)
  }

  return null;
}

const getFirmColor = (idFirm, firms) => {
  return firms.find((firm) => firm.id === idFirm).color;
};

const getFirmName = (idFirm, firms) => {
  return firms.find((firm) => firm.id === idFirm).name;
}

const truncate = (text, truncateLength = 50) => {
  return text?.length > truncateLength ? text.substring(0, truncateLength) + '...' : text;
}

export default ProductMonitorPage;
