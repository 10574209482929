import { createSlice } from '@reduxjs/toolkit';
import {
  NameSpace,
  SortType,
  LocalStorageKey,
  TypeShowValue
} from '../../const';

import {
  fetchFirmsByIdUser,
  setFirmsActiveByIdUser,
  fetchCurrentProductById,
  fetchProductsWithSummaryDetail,
  fetchProductWithDetailStats,
  fetchProductsGroups,
  createProduct,
  updateProduct,
  deleteProduct,
  createLink,
  updateLink,
  deleteLink,
  createProject,
  createMarks,
  fetchAllMarks,
  fetchAllProjects,
  fetchParseData,
  fetchReports,
  deleteAll,
  checkSyncUrlXMLForBitrix,
  fetchSettingsSyncBitrix,
  setSettingsSyncBitrix,
  fetchReportsIntegrations,
  fetchStartSyncBitrix
} from '../api-actions.js';

const initialState = {
  user: {
    id: 1,
    firstName: 'Dmitry',
    lastName: 'Antushev',
    email: 'dima.a@eurotechspb.com',
    login: 'dima.a@eurotechspb.com'
  },
  firms: [],
  products: [],
  productWithDetailStats: {},
  currentProduct: null,
  groups: [],
  marks: [],
  projects: [],
  parseData: null,
  reports: [],
  reportsIntegrations: [],
  sortType: null,
  typeShowValue: TypeShowValue.PRICE,
  checkDataSyncUrlXMLForBitrix: {},
  settingsSyncBitrix: {},
  hasCreateNewProject: false,
  hasDeleteAllData: false,
  hasLoadFirms: false,
  hasLoadProducts: false,
  hasLoadProduct: false,
  hasLoadMarks: false,
  hasLoadParseData: false,
  hasLoadLink: false,
  hasCheckSyncUrlXMLForBitrix: false,
  hasLoadSettingsSyncBitrix: false,
  hasLoadReportsIntegrations: true,
  hasSyncBitrixProducts: false
}

export const appData = createSlice({
  name: NameSpace.DATA,
  initialState,
  reducers: {
    loadFirms: (state, action) => {
      state.firms = [];

      state.firms = action.payload;
    },
    loadProducts: (state, action) => {
      state.products = [];

      state.products = action.payload;
    },
    setCurrentProduct: (state, action) => {
      state.currentProduct = [];

      state.currentProduct = action.payload;
    },
    loadMarks: (state, action) => {
      state.marks = [];

      state.marks = action.payload;
    },
    sortMarks: (state) => {
      if (state.sortType === SortType.TOTAL_SHOW_ASC) {
        state.marks.sort((firstMark, secondMark) => {
          return firstMark.totalShow - secondMark.totalShow;
        });

        state.sortType = SortType.TOTAL_SHOW_DESC;
      } else {
        state.marks.sort((firstMark, secondMark) => {
          return secondMark.totalShow - firstMark.totalShow;
        });

        state.sortType = SortType.TOTAL_SHOW_ASC;
      }
    },
    setParseData: (state, action) => {
      state.parseData = action.payload;
    },
    setTypeShowValue: (state, action) => {
      const typeShowValue = action.payload;

      state.typeShowValue = typeShowValue;

      localStorage.setItem(LocalStorageKey.TYPE_SHOW_VALUE, typeShowValue);
    },
    setCheckDataUrlXMLForBitrix: (state, action) => {
      state.checkDataSyncUrlXMLForBitrix = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      // ПОЛУЧЕНИЕ СПИСКА ФИРМ
      .addCase(fetchFirmsByIdUser.pending, (state) => {
        state.hasLoadFirms = true;
      })
      .addCase(fetchFirmsByIdUser.fulfilled, (state, action) => {
        state.firms = action.payload;
        state.hasLoadFirms = false;
      })
      .addCase(fetchFirmsByIdUser.rejected, (state) => {
        state.hasLoadFirms = false;
      })
      // ВЫБОР АКТИВНЫХ ФИРМ ДЛЯ ОПРЕДЕЛЁННОГО ПОЛЬЗОВАТЕЛЯ
      .addCase(setFirmsActiveByIdUser.pending, (state) => {
        state.hasLoadFirms = true;
      })
      .addCase(setFirmsActiveByIdUser.fulfilled, (state, action) => {
        state.hasLoadFirms = false;
      })
      .addCase(setFirmsActiveByIdUser.rejected, (state) => {
        state.hasLoadFirms = false;
      })
      // ПОЛУЧЕНИЕ ПРОДУКТА ПО ID
      .addCase(fetchCurrentProductById.pending, (state) => {

      })
      .addCase(fetchCurrentProductById.fulfilled, (state, action) => {
        state.currentProduct = action.payload;
      })
      .addCase(fetchCurrentProductById.rejected, (state) => {

      })
      // ПОЛУЧЕНИЕ СПИСКА ПРОУДКТОВ
      .addCase(fetchProductsWithSummaryDetail.pending, (state) => {
        state.hasLoadProducts = true;
      })
      .addCase(fetchProductsWithSummaryDetail.fulfilled, (state, action) => {
        state.products = [];

        state.products = action.payload;

        state.hasLoadProducts = false;
      })
      .addCase(fetchProductsWithSummaryDetail.rejected, (state) =>{
        state.hasLoadProducts = false;
      })
      // ПОЛУЧЕНИЕ ПРОДУКТА С ДЕТАЛЬНОЙ СТАТИСТИКОЙ
      .addCase(fetchProductWithDetailStats.pending, (state, action) => {
        state.hasLoadProduct = true;
      })
      .addCase(fetchProductWithDetailStats.fulfilled, (state, action) => {
        state.productWithDetailStats = action.payload;

        state.hasLoadProduct = false;
      })
      .addCase(fetchProductWithDetailStats.rejected, (state) => {
        state.hasLoadProduct = false;
      })
      // ПОЛУЧЕНИЕ СПИСКА ГРУПП ПОЛЬЗОВАТЕЛЯ
      .addCase(fetchProductsGroups.pending, (state) => {

      })
      .addCase(fetchProductsGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(fetchProductsGroups.rejected, (state) => {

      })
      // СОЗДАНИЕ НОВОГО ПРОДУКТА
      .addCase(createProduct.pending, (state) => {
        state.hasLoadProducts = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        const product = action.payload;
        const firms = state.firms;

        const stats = firms.map((firm) => {
          return {
            idFirm: firm.id,
            linkProduct: null,
            price: null,
            count: null
          }
        });

        state.products.push({...product, stats});

        state.hasLoadProducts = false;
      })
      .addCase(createProduct.rejected, (state) => {
        state.hasLoadProducts = false;
      })
      // РЕДАКТИРОВАНИЕ СУЩЕСТВУЮЩЕГО ПРОДУКТА
      .addCase(updateProduct.pending, (state) => {
        state.hasLoadProduct = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const { product: newProduct } = action.payload;

        const indexNewProduct = state.products.findIndex((product) => {
          return product.id === newProduct.id;
        });

        if (state.products[indexNewProduct].idParent !== newProduct.idParent) {
          state.products.splice(indexNewProduct, 1);
        } else {
          state.products[indexNewProduct] = {...state.products[indexNewProduct], ...newProduct};
        }

        state.hasLoadProduct = false;
      })
      .addCase(updateProduct.rejected, (state) => {
        state.hasLoadProduct = false;
      })
      // УДАЛЕНИЕ СУЩЕСТВУЮЩЕГО ПРОДУКТА
      .addCase(deleteProduct.pending, (state) => {
        state.hasLoadProduct = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const deleteProduct = action.payload;

        state.products = state.products.filter((product) => product.id !== deleteProduct.id);

        state.hasLoadProduct = false;
      })
      .addCase(deleteProduct.rejected, (state) => {
        state.hasLoadProduct = false;
      })
      // СОЗДАНИЕ ССЫЛКИ
      .addCase(createLink.pending, (state) => {
        state.hasLoadLink = true;
      })
      .addCase(createLink.fulfilled, (state) => {
        state.hasLoadLink = false;
      })
      .addCase(createLink.rejected, (state) => {
        state.hasLoadLink = false;
      })
      // РЕДАКТИРОВАНИЕ ТЕКУЩЕЙ ССЫЛКИ
      .addCase(updateLink.pending, (state) => {
        state.hasLoadLink = true;
      })
      .addCase(updateLink.fulfilled, (state, action) => {
        state.hasLoadLink = false;
      })
      .addCase(updateLink.rejected, (state) => {
        state.hasLoadLink = false;
      })
      // УДАЛЕНИЕ ТЕКУЩЕЙ ССЫЛКИ
      .addCase(deleteLink.pending, (state) => {
        state.hasLoadLink = true;
      })
      .addCase(deleteLink.fulfilled, (state, action) => {
        const deleteLink = action.payload;

        const { id: idLink } = deleteLink;

        console.log('Удалённая ссылка: ', idLink);

        state.products = state.products.map((product) => {
          const stats = product.stats?.map((stat) => {
            if (stat.idLink === idLink) {
              return {
                idProduct: null,
                idFirm: stat.idFirm,
                price: null,
                count: null,
                linkProduct: null
              }
            }

            return stat;
          });

          return {
            ...product,
            stats
          }
        })

        state.hasLoadLink = false;
      })
      .addCase(deleteLink.rejected, (state) => {
        state.hasLoadLink = false;
      })
      // ПОЛУЧЕНИЕ СПИСКА МАРКИРОВОК
      .addCase(fetchAllMarks.pending, (state) => {
        if (!state.marks.length > 0) {
          state.hasLoadMarks = true;
        }
      })
      .addCase(fetchAllMarks.fulfilled, (state, action) => {
        state.marks = [];
        if (state.sortType !== null && state.sortType === SortType.TOTAL_SHOW_DESC) {
          state.marks = action.payload.sort((firstProduct, secondProduct) => {
            return firstProduct.totalShow - secondProduct.totalShow;
          });
        } else {
          state.marks = action.payload.sort((firstProduct, secondProduct) => {
            return secondProduct.totalShow - firstProduct.totalShow;
          });
        }

        state.hasLoadMarks = false;
      })
      .addCase(fetchAllMarks.rejected, (state) => {
        state.hasLoadMarks = false;
      })
      //  ЗАПИСЬ В БАЗУ ДАННЫХ НОВЫХ МАРКИРОВОК
      .addCase(createMarks.pending, (state) => {

      })
      .addCase(createMarks.fulfilled, (state, action) => {
        console.log('Товары записаны в базу данных: ', action.payload);
      })
      .addCase(createMarks.rejected, (state) => {

      })
      // ЗАПИСЬ ИНФОРМАЦИИ О ПРОЕКТЕ В БАЗУ ДАННЫХ
      .addCase(createProject.pending, (state) => {
        state.hasCreateNewProject = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.hasCreateNewProject = false;
      })
      .addCase(createProject.rejected, (state) => {
        state.hasCreateNewProject = false;
      })
      // ПОЛУЧЕНИЕ СПИСКА ПРОЕКТОВ
      .addCase(fetchAllProjects.pending, (state) => {

      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.projects = action.payload;
      })
      .addCase(fetchAllProjects.rejected, (state) => {

      })
      // ПОЛУЧЕНИЕ ЦЕНЫ И НАЛИЧИЯ ПО ССЫЛКЕ
      .addCase(fetchParseData.pending, (state) => {
        state.hasLoadParseData = true;
      })
      .addCase(fetchParseData.fulfilled, (state, action) => {
        state.parseData = action.payload;

        state.hasLoadParseData = false;
      })
      .addCase(fetchParseData.rejected, (state) => {
        state.hasLoadParseData = false;
      })
      // ПОЛУЧЕНИЕ ВСЕХ ОТЧЁТОВ
      .addCase(fetchReports.pending, (state) => {

      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.reports = action.payload;
      })
      .addCase(fetchReports.rejected, (state) => {

      })
      // УДАЛЕНИЕ ВСЕХ ДАННЫХ
      .addCase(deleteAll.pending, (state) => {
        state.hasDeleteAllData = true;
      })
      .addCase(deleteAll.fulfilled, (state) => {
        state.hasDeleteAllData = false;
        state.projects = [];
        state.products = [];
      })
      .addCase(deleteAll.rejected, (state) => {
        state.hasDeleteAllData = false;
      })
      // ПРОВЕРКА ССЫЛКИ ДЛЯ СИНХРОНИЗАЦИИ BITRIX
      .addCase(checkSyncUrlXMLForBitrix.pending, (state) => {
        state.hasCheckSyncUrlXMLForBitrix = true;
      })
      .addCase(checkSyncUrlXMLForBitrix.fulfilled, (state, action) => {
        state.checkDataSyncUrlXMLForBitrix = action.payload;

        state.hasCheckSyncUrlXMLForBitrix = false;
      })
      .addCase(checkSyncUrlXMLForBitrix.rejected, (state) => {
        state.hasCheckSyncUrlXMLForBitrix = false;
      })
      // ПОЛУЧЕНИЕ НАСТРОЕК ИНТЕГРАЦИИ С БИТРИКС
      .addCase(fetchSettingsSyncBitrix.pending, (state) => {
        state.hasLoadSettingsSyncBitrix = true;
      })
      .addCase(fetchSettingsSyncBitrix.fulfilled, (state, action) => {
        state.settingsSyncBitrix = action.payload;

        state.hasLoadSettingsSyncBitrix = false;
      })
      .addCase(fetchSettingsSyncBitrix.rejected, (state) => {
        state.hasLoadSettingsSyncBitrix = false;
      })
      // СОХРАНЕНИЕ НАСТРОЕК ИНТЕГРАЦИИ С БИТРИКС
      .addCase(setSettingsSyncBitrix.pending, (state) => {
        state.hasLoadSettingsSyncBitrix = true;
      })
      .addCase(setSettingsSyncBitrix.fulfilled, (state, action) => {
        console.log(action.payload);

        state.settingsSyncBitrix = action.payload;

        state.hasLoadSettingsSyncBitrix = false;
      })
      .addCase(setSettingsSyncBitrix.rejected, (state) => {
        state.hasLoadSettingsSyncBitrix = false;
      })
      // ПОЛУЧЕНИЕ ИНТЕГРАЦИОННЫХ ОТЧЁТОВ
      .addCase(fetchReportsIntegrations.pending, (state) => {
        if (!state.reportsIntegrations.length > 0) {
          state.hasLoadReportsIntegrations = true;
        }
      })
      .addCase(fetchReportsIntegrations.fulfilled, (state, action) => {
        state.reportsIntegrations = action.payload;

        state.hasLoadReportsIntegrations = false;
      })
      .addCase(fetchReportsIntegrations.rejected, (state) => {
        state.hasLoadReportsIntegrations = false;
      })
      // СИНХРОНИЗАЦИЯ С БИТРИКСОМ
      .addCase(fetchStartSyncBitrix.pending, (state) => {
        state.hasSyncBitrixProducts = true;
      })
      .addCase(fetchStartSyncBitrix.fulfilled, (state) => {
        state.hasSyncBitrixProducts = false;
      })
      .addCase(fetchStartSyncBitrix.rejected, (state) => {
        state.hasSyncBitrixProducts = false;
      })
  }
})

export const {
  loadFirms,
  loadProducts,
  setCurrentProduct,
  loadMarks,
  sortMarks,
  setParseData,
  setTypeShowValue,
  setCheckDataUrlXMLForBitrix
} = appData.actions;
