export const NameSpace = {
  DATA: 'DATA',
  PRODUCTS: 'PRODUCTS'
}

export const AppRoute = {
  Root: '/',
  Analysis: '/analysis',
  Monitoring: '/monitoring',
  ProductMonitor: '/monitoring/:idProduct',
  Settings: '/settings/integrations'
}

export const MenuItem = {
  Analysis: 'Analysis',
  Monitoring: 'Monitoring',
  Settings: 'Settings'
}

export const SortType = {
  TOTAL_SHOW_ASC: 'TOTAL_SHOW_ASC',
  TOTAL_SHOW_DESC: 'TOTAL_SHOW_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
}

export const LocalStorageKey = {
  TYPE_SHOW_VALUE: 'type-show-value'
}

export const TypeShowValue = {
  PRICE: 'PRICE',
  COUNT: 'COUNT'
}
