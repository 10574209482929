import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  AppRoute,
  LocalStorageKey,
  TypeShowValue
} from '../../const';

import { setTypeShowValue } from '../../store/app-data/app-data.js';

import Menu from "../menu/menu.jsx";
import AnalysisPage from './../../pages/analysis-page/analysis-page.jsx';
import MonitoringPage from './../../pages/monitoring-page/monitoring-page.jsx';
import ProductMonitorPage from './../../pages/product-monitor-page/product-monitor-page.jsx';
import IntegrationPage from './../../pages/integration-page/integration-page.jsx';
// import SettingsPage from '../../pages/NOT_settings-page/settings-page.jsx';
import NotFoundPage from './../../pages/not-found-page/not-found-page.jsx';

const App = () => {
  const dispatch = useDispatch();

  if (!localStorage.getItem(LocalStorageKey.TYPE_SHOW_VALUE)) {
    localStorage.setItem(LocalStorageKey.TYPE_SHOW_VALUE, TypeShowValue.PRICE);
  }

  const typeShowValue = localStorage.getItem(LocalStorageKey.TYPE_SHOW_VALUE);
  dispatch(setTypeShowValue(typeShowValue));

  return (
    <>
      <Menu />
      <HelmetProvider>
        <Routes>
          <Route path={ AppRoute.Root } element={ <MonitoringPage /> } />
          <Route path={ AppRoute.Analysis } element={ <AnalysisPage /> } />
          <Route path={ AppRoute.ProductMonitor } element={ <ProductMonitorPage /> } />
          <Route path={ AppRoute.Monitoring } element={ <MonitoringPage /> } />
          <Route path={ AppRoute.Settings } element={ <IntegrationPage /> } />
          <Route path='*' element={ <NotFoundPage /> } />
        </Routes>
      </HelmetProvider>
    </>

  )
}

export { App };
